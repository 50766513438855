import { graphql } from 'gatsby'
import React, { useState } from 'react'
import Layout from '../../../../src/common/components/Layout'
import { t } from '../../../../src/common/i18n/index'
import styles from './index.module.scss'
import { SeoData } from '../../../../src/common/types/Seo'

interface Props {
  data: {
    seoData: SeoData
  }
}
const recursosHumanos = ({ data, ...props }: Props) => {
  const [menu, setMenu] = useState(null)

  const handleMenu = index => {
    if (index === menu) {
      setMenu(null)
    } else {
      setMenu(index)
    }
  }
  return (
    <Layout selector={false} seoData={data.seoData}>
      <div className={styles.FAQ}>
        <div className={styles.FAQ_title_cont}>
          <h1 className={styles.FAQ_title}>{t('preguntas.titulo')}</h1>

          <div className={styles.accordeon_container}>
            <div className={styles.accordeon} onClick={() => handleMenu(1)}>
              <div className={styles.header}>
                {menu != 1 ? '+ ' : '- '}
                {t('preguntas.bloque1.titulo1')}
              </div>
              <div
                className={
                  menu != 1 ? `${styles.text} ${styles.collapsed}` : styles.text
                }>
                <p className={styles.bold}>{t('preguntas.bloque1.texto1')}</p>
                <p>{t('preguntas.bloque1.texto2')}</p>
                <p className={styles.bold}>{t('preguntas.bloque1.texto3')}</p>
                <p>{t('preguntas.bloque1.texto4')}</p>
                <p className={styles.bold}>{t('preguntas.bloque1.texto5')}</p>
                <p>{t('preguntas.bloque1.texto6')}</p>
                <p className={styles.bold}>{t('preguntas.bloque1.texto7')}</p>
                <p>{t('preguntas.bloque1.texto8')}</p>
                <p className={styles.bold}>{t('preguntas.bloque1.texto9')}</p>
                <p>{t('preguntas.bloque1.texto10')}</p>
                <p className={styles.bold}>{t('preguntas.bloque1.texto11')}</p>
                <p>{t('preguntas.bloque1.texto12')}</p>
                <p className={styles.bold}>{t('preguntas.bloque1.texto13')}</p>
                <p>{t('preguntas.bloque1.texto14')}</p>
                <p className={styles.bold}>{t('preguntas.bloque1.texto15')}</p>
                <p>{t('preguntas.bloque1.texto16')}</p>
              </div>
            </div>
            <div className={styles.accordeon} onClick={() => handleMenu(2)}>
              <div className={styles.header}>
                {menu != 2 ? '+ ' : '- '}
                {t('preguntas.bloque2.titulo1')}
              </div>
              <div
                className={
                  menu != 2 ? `${styles.text} ${styles.collapsed}` : styles.text
                }>
                <p className={styles.bold}>{t('preguntas.bloque2.texto1')}</p>
                <p>{t('preguntas.bloque2.texto2')}</p>
                <p className={styles.bold}>{t('preguntas.bloque2.texto3')}</p>
                <p>{t('preguntas.bloque2.texto4')}</p>
                <p className={styles.bold}>{t('preguntas.bloque2.texto5')}</p>
                <p>{t('preguntas.bloque2.texto6')}</p>
                <p className={styles.bold}>{t('preguntas.bloque2.texto7')}</p>
                <p>{t('preguntas.bloque2.texto8')}</p>
                <p className={styles.bold}>{t('preguntas.bloque2.texto9')}</p>
                <p>{t('preguntas.bloque2.texto10')}</p>
                <p className={styles.bold}>{t('preguntas.bloque2.texto11')}</p>
                <p>{t('preguntas.bloque2.texto12')}</p>
              </div>
            </div>{' '}
            <div className={styles.accordeon} onClick={() => handleMenu(3)}>
              <div className={styles.header}>
                {menu != 3 ? '+ ' : '- '}
                {t('preguntas.bloque3.titulo1')}
              </div>
              <div
                className={
                  menu != 3 ? `${styles.text} ${styles.collapsed}` : styles.text
                }>
                <p className={styles.bold}>{t('preguntas.bloque3.texto1')}</p>
                <p>{t('preguntas.bloque3.texto2')}</p>
                <p className={styles.bold}>{t('preguntas.bloque3.texto3')}</p>
                <p>{t('preguntas.bloque3.texto4')}</p>
                <p className={styles.bold}>{t('preguntas.bloque3.texto5')}</p>
                <p>{t('preguntas.bloque3.texto6')}</p>
                <p className={styles.bold}>{t('preguntas.bloque3.texto7')}</p>
                <p>{t('preguntas.bloque3.texto8')}</p>
              </div>
            </div>{' '}
            <div className={styles.accordeon} onClick={() => handleMenu(4)}>
              <div className={styles.header}>
                {menu != 4 ? '+ ' : '- '}
                {t('preguntas.bloque4.titulo1')}
              </div>
              <div
                className={
                  menu != 4 ? `${styles.text} ${styles.collapsed}` : styles.text
                }>
                <p className={styles.bold}>{t('preguntas.bloque4.texto1')}</p>
                <p>{t('preguntas.bloque4.texto2')}</p>
                <p className={styles.bold}>{t('preguntas.bloque4.texto3')}</p>
                <p>{t('preguntas.bloque4.texto4')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default recursosHumanos

export const pageQuery = graphql`
  query faqPageQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
